import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, resolveDirective as _resolveDirective, withDirectives as _withDirectives, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "product-container" }
const _hoisted_2 = { class: "p-d-flex p-justify-start p-flex-wrap" }
const _hoisted_3 = { class: "card p-px-2" }
const _hoisted_4 = { class: "p-d-inline-flex" }
const _hoisted_5 = ["onClick"]
const _hoisted_6 = { class: "description p-text-capitalize" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_PosDiscountProduct = _resolveComponent("PosDiscountProduct")!
  const _component_PosProductCard = _resolveComponent("PosProductCard")!
  const _component_PosChooseStock = _resolveComponent("PosChooseStock")!
  const _component_OverlayPanel = _resolveComponent("OverlayPanel")!
  const _directive_ripple = _resolveDirective("ripple")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_Button, {
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onCaretClick(true))),
            class: "p-button-sm p-mr-2",
            icon: "pi pi-folder",
            loading: _ctx.catLoading
          }, null, 8, ["loading"]),
          _createVNode(_component_Button, {
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onCaretClick())),
            class: "p-button-sm",
            icon: "pi pi-caret-up"
          }),
          (_ctx.allowDiscountLines)
            ? (_openBlock(), _createBlock(_component_PosDiscountProduct, {
                key: 0,
                onAddDiscount: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onShop($event[0], $event[1], _ctx.hasSequence))),
                "allow-discount-lines": _ctx.allowDiscountLines
              }, null, 8, ["allow-discount-lines"]))
            : _createCommentVNode("", true)
        ])
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list.categories, (cat) => {
        return _withDirectives((_openBlock(), _createElementBlock("div", {
          key: cat.id,
          onClick: ($event: any) => (Object.assign(_ctx.category, cat)),
          style: _normalizeStyle(`background-image:url('${_ctx.getImage(cat.image, 'categories')}')`),
          class: "card p-text-bold p-ripple cursor-pointer"
        }, [
          _createElementVNode("div", _hoisted_6, _toDisplayString(cat.label), 1)
        ], 12, _hoisted_5)), [
          [_directive_ripple]
        ])
      }), 128)),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list.products, (prod) => {
        return (_openBlock(), _createBlock(_component_PosProductCard, {
          key: prod.id,
          product: prod,
          "stock-total": _ctx.totalStatus(prod),
          "stock-status": _ctx.stockStatus(prod),
          onOnShop: ($event: any) => (_ctx.onShop($event, prod, _ctx.hasSequence))
        }, null, 8, ["product", "stock-total", "stock-status", "onOnShop"]))
      }), 128))
    ]),
    _createVNode(_component_OverlayPanel, {
      ref: "stockOverlay",
      dismissable: true
    }, {
      default: _withCtx(() => [
        (_ctx.hasSequence)
          ? (_openBlock(), _createBlock(_component_PosChooseStock, {
              key: 0,
              quantity: _ctx.saleRow.quantity,
              "onUpdate:quantity": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.saleRow.quantity) = $event)),
              id: _ctx.saleRow.stockId,
              "onUpdate:id": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.saleRow.stockId) = $event)),
              stocks: _ctx.stocks,
              onClose: _ctx.onQuantityValidate
            }, null, 8, ["quantity", "id", "stocks", "onClose"]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 512)
  ]))
}