
import { getImage } from "@/graphql/utils/utils";
import { defineAsyncComponent, defineComponent, ref } from "vue";

export default defineComponent({
  name: "PosProductCard",
  props: ["product", "stockStatus", "stockTotal"],
  emits: ["on-shop"],
  components: {
    PosDetailsStock: defineAsyncComponent(
      () => import("./PosDetailsStock.vue")
    ),
  },
  setup() {
    const showStockDetails = ref(false);
    return {
      showStockDetails,
      getImage,
    };
  },
});
