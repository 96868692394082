
import { defineComponent } from "vue";
import StockDropdown from "@/components/stock/StockDropdown.vue";
import { activeActivity } from "@/plugins/i18n";
import { numberSeparators } from "@/graphql/utils/utils";

export default defineComponent({
  name: "PosChooseStock",
  components: { StockDropdown },
  props: ["stocks", "id", "quantity"],
  emits: ["update:id", "update:quantity", "close"],
  setup() {
    return {
      activeActivity,
      numberSeparators,
    };
  },
});
