
import { defineComponent, computed, ref } from "vue";
import { Stock } from "@/graphql/types";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "StockDropdown",
  props: ["stocks", "modelValue", "hasError", "currency"],
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const { n, t } = useI18n();
    const selected = ref<Stock>(null);
    selected.value = props.stocks.find((s: Stock) => s.id === props.modelValue);
    emit("update:modelValue", selected.value?.id);
    return {
      label: computed(() => {
        if (!selected.value) return "";
        return (
          n(selected.value.inclTax, "decimal") +
          " " +
          props.currency +
          ", " +
          t("stock.stock").toLowerCase() +
          " : " +
          selected.value.quantity
        );
      }),
      selected,
    };
  },
  methods: {
    toggleDataTable(event: any) {
      this.$refs.stock_op2.toggle(event);
    },
    onSelect(id: number) {
      this.$emit("update:modelValue", id);
      this.$refs.stock_op2.hide();
    },
  },
});
