import { Category, QueryProductCategoriesArgs } from "src/graphql/types";
import { gql } from "@apollo/client";
import { useQuery, useResult } from "@vue/apollo-composable";
import { CATEGORY_FIELDS } from "@/graphql/category/category";
import { useI18n } from "vue-i18n";
import { activeActivity } from "@/plugins/i18n";

type FindAllCategoriesData = {
  productCategories: Category[];
};

const PRODUCT_CATEGORIES = gql`
  query ProductCategories($input: Int!) {
      productCategories(input: $input) {
      ${CATEGORY_FIELDS}
    }
  }
`;
export const useFindAllCategories = () => {
  const { t } = useI18n();
  const { loading, result } = useQuery<
    FindAllCategoriesData,
    QueryProductCategoriesArgs
  >(PRODUCT_CATEGORIES, { input: activeActivity.value.id });
  const categories = useResult<FindAllCategoriesData, Category[], Category[]>(
    result,
    [],
    (res) => {
      const categs = [...(res?.productCategories || [])];
      const rootCategs = categs.splice(0, 2).map((root) => ({
        ...root,
        label: t(`category.${root.label}`),
      }));
      categs.sort((c1, c2) => c1.label.localeCompare(c2.label));
      return rootCategs.concat(categs);
    }
  );
  return { loading, categories };
};
