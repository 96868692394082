import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withKeys as _withKeys, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "p-field" }
const _hoisted_2 = { for: "quantity" }
const _hoisted_3 = { class: "p-inputgroup" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_StockDropdown = _resolveComponent("StockDropdown")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_Button = _resolveComponent("Button")!

  return (_ctx.stocks.length)
    ? (_openBlock(), _createElementBlock("form", {
        key: 0,
        autocomplete: "off",
        onSubmit: _cache[3] || (_cache[3] = _withModifiers(($event: any) => (_ctx.$emit('close', $event)), ["prevent"])),
        class: "p-fluid"
      }, [
        _createVNode(_component_StockDropdown, {
          currency: _ctx.activeActivity.currencySymbol,
          stocks: _ctx.stocks,
          "model-value": _ctx.id,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:id', $event)))
        }, null, 8, ["currency", "stocks", "model-value"]),
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("label", _hoisted_2, _toDisplayString(_ctx.$t("product.quantity")), 1),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_InputNumber, {
              showButtons: "",
              buttonLayout: "horizontal",
              decrementButtonClass: "p-button-danger addon",
              incrementButtonClass: "p-button-success addon",
              incrementButtonIcon: "pi pi-plus",
              decrementButtonIcon: "pi pi-minus",
              "model-value": _ctx.quantity,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:quantity', $event))),
              mode: "decimal",
              class: "p-inputtext-sm",
              id: "quantity",
              locale: _ctx.numberSeparators[_ctx.activeActivity.separator],
              "max-fraction-digits": 20,
              "allow-empty": false,
              min: 0,
              onKeyup: _cache[2] || (_cache[2] = _withKeys(($event: any) => (_ctx.$emit('close', $event)), ["enter"]))
            }, null, 8, ["model-value", "locale"]),
            _createVNode(_component_Button, {
              class: "p-ml-3",
              type: "submit",
              icon: "pi pi-check"
            })
          ])
        ])
      ], 32))
    : _createCommentVNode("", true)
}