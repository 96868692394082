
import { defineComponent, reactive, computed, defineAsyncComponent } from "vue";
import { useFindAllCategories } from "@/graphql/category/find.all.categories";
import { useI18n } from "vue-i18n";
import { getImage, numberSeparators } from "@/graphql/utils/utils";
import { activeActivity } from "@/plugins/i18n";
import { usePosProduct } from "@/components/pos/pos-product";
import PosChooseStock from "@/components/pos/PosChooseStock.vue";
import PosProductCard from "./PosProductCard.vue";

export default defineComponent({
  name: "PosProduct",
  props: ["categories", "hasSequence", "products"],
  emits: ["addToTicketRow"],
  components: {
    PosChooseStock,
    PosProductCard,
    PosDiscountProduct: defineAsyncComponent(
      () => import("./PosDiscountProduct.vue")
    ),
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const { categories, loading: catLoading } = useFindAllCategories();
    const category = reactive({
      id: 1,
      label: t("category.principal"),
      parentId: null,
      image: "",
    });

    function isUnmasked(id: number): boolean {
      if (id === 1) return true;
      return props.categories.findIndex((ct) => ct.categoryId === id) > -1;
    }
    const list = computed(() => {
      const unMasked =
        props.categories.length > 0
          ? categories.value.filter((c) => isUnmasked(c.id))
          : [...categories.value];
      return {
        categories: unMasked.filter((c) => c.parentId === category.id),
        products: props.products.filter((p) => p.category.id === category.id),
        unMasked,
      };
    });

    function onCaretClick(root = false) {
      if (!root) {
        const parent = list.value.unMasked.find(
          (c) => c.id === category.parentId
        );
        if (parent) Object.assign(category, parent);
      } else Object.assign(category, list.value.unMasked[0]);
    }

    return {
      onCaretClick,
      list,
      catLoading,
      category,
      getImage,
      activeActivity,
      numberSeparators,
      ...usePosProduct(emit),
    };
  },
});
